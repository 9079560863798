.notifications-filter-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    height: 300px;
    gap: 15px;
}

.clear-filters-button {
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 10px;
    text-align: center;
    user-select: none;
    font-size: 15px;
    color: royalblue;
}
