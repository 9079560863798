@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    /*font-family: 'Nunito', sans-serif;*/
}

html {
    scroll-behavior: smooth;
}

html, body {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    overscroll-behavior-y: none;
    overscroll-behavior: none;
    overflow-x: auto;
    overflow-y: auto;
}

body {
    min-width: 360px;
}
