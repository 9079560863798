.map-container {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 360px;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

a.mapboxgl-ctrl-logo {
  display: none;
}

.map-marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  background-size: cover;
  z-index: auto;
  opacity: 0.2;
}

.mapboxgl-popup-tip {
  border: 10px solid transparent;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: lightgray;
  align-self: center;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: gray;
  align-self: center;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: lightgray;
  align-self: center;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: lightgray;
  align-self: center;
}

.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  max-width: 400px;
}

.mapboxgl-popup-content {
  display: flex;
  background-color: lightgray;
  border-style: solid;
  border-width: 2px;
  border-color: gray;
  border-radius: 10px;
  padding: 0;
}

.popup-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #c0c0c0;
  border-radius: 10px;
  justify-content: flex-start;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-align: left;
  padding: 6px 9px 7px 9px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard syntax */
}

.vehicle-id-container {
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  font-size: 20px;
  color: forestgreen;
  text-align: center;
  margin: 5px 10px 5px 10px;
  padding: 5px 1px 5px 1px;
}

.battery-container,
.vehicle-type-container,
.last-ride-container,
.last-event-type-container,
.last-markers-update-container,
.vehicle-last-signal-container,
.vehicle-status-container,
.last-location-container,
.vehicle-speed-container,
.previous-vehicle-speed-container {
  display: flex;
  width: 100%;
  align-self: center;
  color: dimgrey;
  background-color: #e3e3e3;
  border-style: solid;
  border-width: 1px;
  border-color: #9b9b9b;
  border-radius: 8px;
  white-space: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  margin: 3px;
  padding: 4px 9px 3px 9px;
  gap: 8px;
}

.vehicle-type-value,
.vehicle-status-value,
.last-event-type-value,
.last-ride-value,
.battery-value,
.last-location-value,
.last-markers-update-value,
.vehicle-last-signal-value,
.vehicle-speed-value,
.previous-vehicle-speed-value {
  display: flex;
  align-self: center;
  align-content: center;
  color: dimgrey;
  background-color: #e3e3e3;
  border-radius: 5px;
}

.vehicle-type-img-value,
.vehicle-status-img-value,
.last-event-type-img-value,
.last-ride-img-value,
.battery-img-value,
.last-location-img-value,
.last-markers-update-img-value,
.vehicle-last-signal-img-value,
.vehicle-speed-img-value,
.previous-vehicle-speed-img-value {
  display: flex;
  align-self: center;
  align-content: center;
  border-radius: 8px;
  width: 31px;
  height: 31px;
  background-size: cover;
}

.last-location-link {
  padding-left: 60px;
  align-self: center;
  align-content: center;
}


.last-markers-update-tooltip-container, .last-ride-tooltip-container, .vehicle-last-signal-tooltip-container {
  position: absolute;
  display: flex;
  width: 100%;
  left: 0;
  right: 0;

  .last-markers-update-tooltip-value, .last-ride-tooltip-value, .vehicle-last-signal-tooltip-value {
    visibility: hidden;
    display: flex;
    background-color: #333;
    color: #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .last-markers-update-tooltip-value::after, .last-ride-tooltip-value::after, .vehicle-last-signal-tooltip-value::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
}

@media (hover: hover) {
  .last-markers-update-container:hover, .last-ride-container:hover, .vehicle-last-signal-container:hover {
    .last-markers-update-tooltip-value, .last-ride-tooltip-value, .vehicle-last-signal-tooltip-value {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (hover: none) {
  .last-markers-update-container:active, .last-ride-container:active, .vehicle-last-signal-container:active {
    .last-markers-update-tooltip-value, .last-ride-tooltip-value, .vehicle-last-signal-tooltip-value {
      visibility: visible;
      opacity: 1;
    }
  }
}

