.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: white;
}

.error-page-container p {
    font-size: 25px;
}