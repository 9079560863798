.vehicle-id-search-results-list {
    position: absolute;
    top: 259px;
    z-index: 1;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid lightgrey;
    padding: 6px 9px 6px 9px;
    margin-top: 5px;
    width: 100%;
    max-width: 265px;
    max-height: 300px;
    overflow-y: auto;
}

@media (max-height: 720px) {
    .vehicle-id-search-results-list {
        height: 150px;
    }
}

.vehicle-id-search-results-list::-webkit-scrollbar {
    width: 10px;
}

.vehicle-id-search-results-list::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 5px;
}

.vehicle-id-search-results-list::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
    min-height: 25px;
}

.get-vehicle-ids-data-error {
    align-items: center;
    justify-content: center;
    margin: 10px;
    text-align: center;
    user-select: none;
    font-size: 15px;
    color: #757575;
}

.get-vehicle-ids-data-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 16px 16px 16px;
}

.get-vehicle-ids-data-loading-spinner {
    width: 4.8px;
    height: 4.8px;
    border-radius: 4.8px;
    box-shadow: 12px 0px 0 0 rgba(87,87,87,0.2), 9.7px 7.1px 0 0 rgba(87,87,87,0.4), 3.7199999999999998px 11.4px 0 0 rgba(87,87,87,0.6), -3.7199999999999998px 11.4px 0 0 rgba(87,87,87,0.8), -9.7px 7.1px 0 0 #575757;
    animation: spinner 1s infinite linear;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
