.vehicle-search-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 25px;
  border: none;
  border-radius: 10px;
  padding: 0 9px;
  background-color: #ebebeb;
}

.vehicle-search-input-wrapper input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 15px;
  width: 100%;
  margin-left: 1px;
  margin-right: 1px;
  padding-left: 2px;
  color: #757575
}

.vehicle-search-input-wrapper input:focus {
  outline: none;
}

#vehicle-search-input-wrapper-icon {
  color: royalblue;
}
