.select-location-dropdown-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: 100%;
  height: 25px;
  border: none;
  border-radius: 10px;
  padding: 0 9px;
  background-color: #ebebeb;
}

@media (min-width: 449px) {
  .select-location-dropdown-wrapper {
    width: 135px;
  }
}

.select-location-dropdown-wrapper-name {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 15px;
  color: #757575;
  user-select: none;
}

@media (max-width: 524px) {
  .select-location-dropdown-wrapper-name {
    display: none;
  }
  .select-location-dropdown-wrapper {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 5px;
  }
}

.select-location-dropdown-wrapper svg {
  padding-left: 0;
  font-size: 23px;
  user-select: none;
  filter: invert(36%) sepia(89%) saturate(890%) hue-rotate(200deg) brightness(91%) contrast(95%);
}

