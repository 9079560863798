.rs-picker .rs-input-group.rs-input-group-inside {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0 9px;
    background-color: #ebebeb;
    height: 45px;
    width: 265px;
    border: 1px solid #757575;
    color: #757575;
}

.rs-picker .rs-input-group.rs-input-group-inside {
    color: #757575;
}

.rs-input-group.rs-input-group-inside input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 15px;
    width: 100%;
    margin-left: 1px;
    margin-right: 1px;
    padding-left: 5px;
    color: #757575;
    font-family: "Poppins", sans-serif;
}

.rs-input-group.rs-input-group-inside input::placeholder {
    font-size: 15px;
    color: #757575;
    font-family: "Poppins", sans-serif;
    user-select: none;
}

.rs-picker.rs-picker-daterange {
    color: #007aff;
    font-family: "Poppins", sans-serif;
}
/*.rs-input-group.rs-input-group-inside {*/
/*    padding: 0 4px;*/
/*    height: 45px;*/
/*    width: 265px;*/
/*    border-radius: 10px;*/
/*    border: 2px solid #757575;*/
/*    background-color: transparent;*/
/*    user-select: none;*/
/*}*/

.rs-input-group.rs-input-group-inside .rs-input {
    background-color: transparent;
    font-size: 15px;
    user-select: none;
}

@media (hover: hover) {
    .rs-input-group.rs-input-group-inside:hover {
        border: 1px solid #757575;
    }
}

@media (hover: none) {
    .rs-input-group.rs-input-group-inside:active {
        border: 1px solid #757575;
    }
}

.rs-input-group-addon svg, .rs-input-group-addon  .rs-picker-clean svg{
    color: royalblue;
    font-size: 15px;
}

.rs-picker-popup-daterange.rs-picker-popup {
    background-color: #ebebeb;
    border: 2px solid lightgrey;
    border-radius: 10px;
    margin-top: 5px;
}
