.vehicle-search-result {
  padding: 10px 20px 10px 20px;
  margin: 5px;
  color: #757575;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (hover: hover) {
  .vehicle-search-result:hover {
    background-color: #757575;
    color: #ebebeb;
  }
}

@media (hover: none) {
  .vehicle-search-result:active {
    background-color: #757575;
    color: #ebebeb;
  }
}
