.navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #757575;
    font-size: 15px;
    position: relative;
    z-index: 1;
}

.navigation-container span, .navigation-container ul, .navigation-container li {
    height: 100%;
}

.navigation-container ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
}

.navigation-container li.active {
    background-color: #555555;
}

.navigation-container a, .about-link a {
    color: #ebebeb;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
}

@media (hover: hover) {
    .home-logo-link:hover  {
        background-color: #ebebeb;
    }
    .home-logo-link:hover .home-logo {
        filter: invert(46%) sepia(0%) saturate(0%) hue-rotate(207deg) brightness(100%) contrast(97%);
    }
}

@media (hover: none) {
    .home-logo-link:active  {
        background-color: #ebebeb;
    }
    .home-logo-link:active .home-logo {
        filter: invert(46%) sepia(0%) saturate(0%) hue-rotate(207deg) brightness(100%) contrast(97%);
    }
}

.home-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover;
    margin-top: 3px;
    width: 85px;
    height: 18px;
    background-repeat: no-repeat;
    border: none;
    background-image: url('../../images/homeLogo.svg');
}

@media (max-width: 413px) {
    .home-logo{
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        width: 21px;
        height: 21px;
        background-image: url('../../images/homeLogo.svg');
    }
}

.about-link {
    margin-left: 10px;
    margin-right: 4px;
}

@media (hover: hover) {
    .about-link:hover {
        background-color: #ebebeb;
        color: #757575;
    }
}

@media (hover: none) {
    .about-link:active {
        background-color: #ebebeb;
        color: #757575;
    }
}

.about-link svg{
    vertical-align: middle;
    padding-top: 2px;
    margin-bottom: 2px;
    font-size: 21px;
}

.search-bar-container, .select-location-bar-container, .notifications-bar-container {
    display: flex;
    position: relative;
    font-size: 15px;
    margin-left: 9px;
    margin-right: 9px;
}

.search-bar-container {
    max-width: 165px;
    min-width: 88px;
}

.color-mode-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 100%;
    margin-left: 4px;
    margin-right: 2px;
    font-size: 29px;
}

.color-mode-btn-container .color-mode-btn {
    display: flex;
    padding: 5px;
    background-color: #757575;
    border: none;
    color: #ebebeb;
    font-size: 15px;
    user-select: none;
    height: 100%;
    width: 100%;
}

.color-mode-btn-container .color-mode-btn svg {
    vertical-align: middle;
    margin-bottom: 1px;
    font-size: 19px;
}

.logout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #757575;
    border: none;
    color: #ebebeb;
    font-size: 15px;
    user-select: none;
    height: 100%;
    max-width:350px;
    min-width: 10px;
    margin-left: 2px;
    padding-left: 5px;
    padding-right: 8px;
}

.logout-btn svg {
    vertical-align: middle;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-right: 2px;
    font-size: 23px;
}

@media (hover: hover) {
    .color-mode-btn:hover, .logout-btn:hover {
        background-color: #ebebeb;
        color: #757575;
    }
}

@media (hover: none) {
    .color-mode-btn:active, .logout-btn:active {
        background-color: #ebebeb;
        color: #757575;
    }
}

@media (max-width: 744px) {
    .color-mode-btn-container {
        width: 35px;
    }
    .color-mode-btn-container .color-mode-btn {
        font-size: 0;
    }
     .logout-btn {
        font-size: 0;
    }
}









