.select-results-list {
  position: absolute;
  top: calc(112%); /* adjust the distance from the navbar as needed */
  z-index: 1; /* make sure the results container is on top of other elements */
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid lightgrey;
  margin: 5px;
  padding: 6px;
  width: 100%;
  min-width: 120px;
  max-height: 333px;
  overflow-y: auto;
  left: -5px;
}

@media (max-width: 524px) {
  .select-results-list {
    left: -55px; /* Adjusted left position for mobile */
  }
}

.select-results-list::-webkit-scrollbar {
  width: 10px;
}

.select-results-list::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
  margin: 5px;
}

.select-results-list::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
