.notifications-window-container {
  position: absolute;
  top: calc(112%);
  z-index: 1;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid lightgrey;
  margin: 5px;
  padding-top: 6px;
  width: 380px;
  min-height: 450px;
  max-height: 750px;
  height: 80vh;
  overflow-y: auto;
  left: -120px;
}

@media (max-width: 744px) and (min-width: 641px) {
  .notifications-window-container {
    left: -240px;
  }
}

@media (max-width: 640px) and (min-width: 436px) {
  .notifications-window-container {
    left: -320px;
  }
}

@media (max-width: 437px) and (min-width: 431px){
  .notifications-window-container {
    left: -311px;
    width: 371px;
  }
}

@media (max-width: 430px) and (min-width: 421px){
  .notifications-window-container {
    left: -302px;
    width: 362px;
  }
}

@media (max-width: 420px) and (min-width: 411px){
  .notifications-window-container {
    left: -293px;
    width: 353px;
  }
}


@media (max-width: 410px) and (min-width: 401px){
  .notifications-window-container {
    left: -284px;
    width: 344px;
  }
}

@media (max-width: 400px) and (min-width: 391px){
  .notifications-window-container {
    left: -275px;
    width: 335px;
  }
}

@media (max-width: 390px) and (min-width: 381px){
  .notifications-window-container {
    left: -266px;
    width: 326px;
  }
}

@media (max-width: 380px) and (min-width: 371px){
  .notifications-window-container {
    left: -257px;
    width: 317px;
  }
}

@media (max-width: 370px) and (min-width: 366px){
  .notifications-window-container {
    left: -250px;
    width: 313px;
  }
}

@media (max-width: 365px) and (min-width: 362px){
  .notifications-window-container {
    left: -247px;
    width: 309px;
  }
}


@media (max-width: 361px){
  .notifications-window-container {
    left: -245px;
    width: 305px;
  }
}

.notifications-window-name {
  display: flex;
  justify-content: center;
  color: #757575;
  font-size: 17px;
}

.notifications-window-container::-webkit-scrollbar {
  width: 10px;
}

.notifications-window-container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
  margin: 5px;
}

.notifications-window-container::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}


