.notifications-window-controls {
    display: flex;
    flex-direction: row;
    border-top: 2px solid lightgrey;
    padding: 6px;
    margin-top: 5px;
    margin-bottom: 2px;
    width: 100%;
    height: 60px;
}

.notifications-filter-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    width: 170px;
    height: 40px;
    color: #757575;
    user-select: none;
    margin-left: 15px;
    gap: 2px
}

@media (hover: hover) {
    .notifications-filter-button:hover {
        color: #1da1f2;
    }
}

@media (hover: none) {
    .notifications-filter-button:active {
        color: #1da1f2;
    }
}

.notifications-filter-button-name {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 0;
    margin-left: 4px;
    height: 100%;
}

.notifications-filter-button-icon {
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 4px;
    height: 100%;
}

.notifications-filter-button-icon svg {
    vertical-align: middle;
    padding-bottom: 1px;
    margin: 0 1px 1px 1px;
    font-size: 18px;
}
