.notifications-window-list-item {
    display: flex;
    margin: 6px 12px;
    color: #757575;
    background-color: #ebebeb;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 331px;
    /*transition: margin-top 0.4s;*/
}

@media (hover: hover) {
    .notifications-window-list-item:hover {
        box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;
    }
}

@media (hover: none) {
    .notifications-window-list-item:active {
        box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;
    }
}
