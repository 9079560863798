.notification-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-align: left;
    border-radius: 10px;
    padding: 12px 15px 12px 15px;
    gap: 8px;
    user-select: none;
    width: 100%;
}

.notification-container-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    text-align: left;
    gap: 10px;
    width: 100%;
}

.notification-container-type svg {
    vertical-align: center;
    font-size: 28px;
    color: crimson;
}

.notification-container-type p{
    font-size: 20px;
}

.notification-container-time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

}

.notification-container-time p {
    align-items: start;
    font-size: 13px;
    opacity: 60%;
    width: 100%;
}

.notification-container-message {
    width: 100%
}

.notification-container-message p {
    font-size: 14px;
    text-align: left;
    opacity: 60%;
}

.notification-container-message p a {
    display: inline;
    margin: 0 5px;
    padding: 0;
    color: blue;
}

