.notifications-window-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 2px solid lightgrey;
    width: 100%;
    overflow-y: auto;
    padding-top: 5px;
    height: 650px;
}

.notifications-window-list::-webkit-scrollbar {
    width: 10px;
}

.notifications-window-list::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 5px;
}

.notifications-window-list::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
}

