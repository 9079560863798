.pull-down-to-refresh-list-spinner-container {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 16px;
    transition: padding 0.5s ease;
}

.pull-down-to-refresh-list-spinner {
    width: 4.8px;
    height: 4.8px;
    border-radius: 4.8px;
    box-shadow: 12px 0px 0 0 rgba(87,87,87,0.2), 9.7px 7.1px 0 0 rgba(87,87,87,0.4), 3.7199999999999998px 11.4px 0 0 rgba(87,87,87,0.6), -3.7199999999999998px 11.4px 0 0 rgba(87,87,87,0.8), -9.7px 7.1px 0 0 #575757;
    animation: spinner 1s infinite linear;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.pull-down-to-refresh-list-expansion-container {
    display: flex;
    margin-bottom: 1px;
    transition: margin-bottom 0.4s;
}