.login-page-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: white;
    font-family: 'Nunito', sans-serif;
    position: relative;
    min-height: 600px;

    .login-poly-background-top, .login-poly-background-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
    }

    .login-poly-background-top svg {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: 30px;
        min-height: 30px;
        max-width: 65px;
        max-height: 65px;
        color: white;
        margin: 15px;
    }

    .login-poly-background-bottom {
        background-position: bottom;
    }

    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-form-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 323px;
        max-width: 323px;
        height: 380px;
        background: white;
    }

    .login-form-container h1 {
        text-align: start;
        padding: 30px 0;
        font-size: 25px;
        line-height: 10px;
        font-weight: 700;
        font-family: 'Nunito', sans-serif;
    }

    .input-container {
        position: relative;
        margin: 20px 0;
    }

    .input-container input {
        width: 100%;
        padding: 12px;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        border: 1px solid #858C94;
        border-radius: 8px;
        font-family: 'Nunito', sans-serif;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
        margin: 30px 0;
    }

    .login-btn {
        width: 100%;
        height: 40px;
        background: #003E60;
        border: 1px solid #858C94;
        border-radius: 5px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: #e9f4fb;
        cursor: pointer;
        outline: none;
        font-family: 'Nunito', sans-serif;
    }

    @media (hover: hover) {
        .login-btn:hover{
            border-color: #003E60;
            transition: .5s;
        }
    }

    @media (hover: none) {
        .login-btn:active{
            border-color: #003E60;
            transition: .5s;
        }
    }

    .login-logo {
        align-self: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 230px;
        min-width: 230px;
        height: 40px;
        margin: 15px;
    }
}

