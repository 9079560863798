.statistics-menu-trigger {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 25px;
    left: 25px;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    border: 2px solid lightgrey;
    overflow: hidden;
    cursor: pointer;
    background-color: #ebebeb;
    font-size: 18px;
}

.statistics-menu-trigger.expanded {
    border-radius: 12px;
    justify-content: space-between;
    height: 40px;
    width: 260px;
    transition: 350ms ease;
}

.statistics-menu-trigger.shrunken {
    border-radius: 50px;
    height: 40px;
    width: 40px;
    transition: 350ms ease;
}

.opened-statistics-menu-name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #ebebeb;
    margin: 10px 0 5px 0;
    padding: 12px 0 22px 0;
    border-bottom: 2px solid lightgrey;
    user-select: none;
}

.statistics-menu-trigger-name, .opened-statistics-menu-name{
    flex-grow: 4;
    text-align: center;
    padding: 5px 1px 5px 15px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2rem;
}

.opened-statistics-menu-name{
    text-align: center;
    padding: 5px 2px 5px 2px;
}

.statistics-menu-trigger-name{
    padding: 5px 0px 5px 0px;
}

.statistics-menu-trigger-name.expanded{
    padding: 5px 1px 5px 15px;
}

.statistics-menu-trigger-icon, .opened-statistics-menu-close-icon, .opened-statistics-menu-name-clear-icon{
    text-align: center;
    margin: 5px 20px 5px 0;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    border-radius: 50%;
    font-weight: 500;
    line-height: 1.2rem;
}

.opened-statistics-menu-close-icon, .opened-statistics-menu-name-clear-icon{
    padding: 8px;
    font-weight: 500;
    line-height: 1.2rem;
}

.opened-statistics-menu-name-clear-icon{
    margin: 5px 0px 5px 15px;
    padding: 8px;
}

.statistics-menu-trigger-icon.shrunken{
    margin: 2px 0 0 0;
    padding: 7px;
}

@media (hover: hover) {
    .opened-statistics-menu-close-icon:hover, .opened-statistics-menu-name-clear-icon:hover{
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 15px 2px;
    }
    .statistics-menu-trigger.shrunken:hover, .statistics-menu-trigger.expanded:hover {
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 15px 2px;
    }
}

@media (hover: none) {
    .opened-statistics-menu-close-icon:active, .opened-statistics-menu-name-clear-icon:active{
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 15px 2px;
    }
    .statistics-menu-trigger.shrunken:active, .statistics-menu-trigger:active, .statistics-menu-trigger-name:active, .statistics-menu-trigger-icon.shrunken:active {
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 15px 2px;
    }
}

.statistics-menu-trigger-icon svg, .opened-statistics-menu-close-icon svg, .opened-statistics-menu-name-clear-icon svg{
    vertical-align: middle;
    padding-bottom: 1px;
    margin: 0 1px 1px 1px;
    font-size: 20px;
}

.statistics-menu-trigger-icon svg {
    padding-left: 0;
}

.dropdown-statistics-menu {
    position: absolute;
    bottom: 25px;
    left: 25px;
    width: 260px;
    height: 440px;
    border-radius: 12px;
    background-color: #ebebeb;
    padding: 10px 20px;
    border: 2px solid lightgrey;
}

.dropdown-statistics-menu::before{
    content: '';
    position: absolute;
    top: -5px;
    right: 120px;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    transform: rotate(45deg);
}

.dropdown-statistics-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 350ms ease;
    padding: 1px;
    transition-delay: 0.4s;
    z-index: 1;
}

.dropdown-statistics-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 350ms ease;
}

.drop-down-list-container{
    margin-bottom: 5px;
    padding-bottom: 5px;
    height: 380px;
    overflow-y: auto;
}

.drop-down-list{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 2px;
    padding: 0 1px 0 1px;
    user-select: none;
}

.drop-down-list-item {
    background: #ebebeb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    padding: 5px 8px;
    border: 1px solid lightgrey;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 100;
    line-height: 1.2rem;
}

.drop-down-list-item.inactive {
    background: #ebebeb;
}

.drop-down-list-item.active {
    background: gray;
    color: white;
    border: 1px solid grey;
}

.image-status-container {
    display: block;
    border-radius: 50%;
    cursor: pointer;
    padding: 15px;
    margin-right: 1px;
    width: 30px;
    height: 30px;
    background-size: cover;
}

.status-container, .count-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    user-select: none;
}

.count-container {
    justify-content: space-evenly;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    min-width: 42px;
    min-height: 42px;
    width: 40px;
    height: 40px;
}

.drop-down-list-container::-webkit-scrollbar {
    width: 8px;
}

.drop-down-list-container::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 5px;
}

.drop-down-list-container::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
}
