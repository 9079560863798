.notification-type-search-results-list {
    position: absolute;
    top: 199px;
    z-index: 1;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid lightgrey;
    padding: 6px 9px 6px 9px;
    margin-top: 4px;
    width: 100%;
    max-width: 265px;
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
}

@media (max-height: 720px) {
    .notification-type-search-results-list {
        height: 150px;
    }
}

.notification-type-search-results-list::-webkit-scrollbar {
    width: 10px;
}

.notification-type-search-results-list::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 5px;
}

.notification-type-search-results-list::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 100px;
    min-height: 25px;
}

.notification-type-search-data-error {
    align-items: center;
    justify-content: center;
    margin: 10px;
    text-align: center;
    user-select: none;
    font-size: 15px;
    color: #757575;
}
