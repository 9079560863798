.select-location-result {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  margin: 5px;
  color: #757575;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  user-select: none;
}

.select-location-result.active{
  background-color: #757575;
  color: #ebebeb;
}

.select-location-result.inactive{
  background-color: #ebebeb;
  color: #757575;
}

@media (hover: hover) {
  .select-location-result.inactive:hover{
    background-color: #757575;
    color: #ebebeb;
  }
}

@media (hover: none) {
  .select-location-result.inactive:active{
    background-color: #757575;
    color: #ebebeb;
  }
}