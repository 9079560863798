.Toastify__toast {
    padding: 0;
    border-radius: 10px;
}

.Toastify__toast-body {
    padding: 0;
}

.toast-container {
    width: 335px;
    right: 5px;
    bottom: 5px;
}

@media (max-width: 480px) {
    .toast-container {
        max-width: 335px;
        width: fit-content;
        bottom: 18px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

    }
    .Toastify__toast {
        margin-bottom: 0.5rem;
    }
}


