.vehicle-id-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 0 9px;
    background-color: #ebebeb;
    height: 45px;
    width: 265px;
    border: 1px solid #757575;
    color: #757575;
}

.vehicle-id-input-wrapper input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 15px;
    width: 100%;
    margin-left: 1px;
    margin-right: 1px;
    padding-left: 5px;
    color: #757575;
    font-family: "Poppins", sans-serif;
}

.vehicle-id-input-wrapper input:focus {
    outline: none;
}

#vehicle-id-input-wrapper-icon {
    color: royalblue;
    margin-right: 12px;
}
