.login-page-container-pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: white;
    font-family: 'Nunito', sans-serif;
    position: relative;
    min-height: 380px;

    .login-poly-background {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        min-width: 150px;
        max-width: 700px;
    }

    .login-poly-background svg {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: 50px;
        min-height: 50px;
        max-width: 100px;
        max-height: 100px;
        color: white;
        margin: 15px;
    }

    .login-center {
        display: flex;
        flex-direction: column;
        width: 323px;
        min-width: 323px;
        background: white;
        margin: 98px;
    }

    .login-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 900px) {
        .login-center {
            margin: 20px;
        }
    }

    @media (max-width: 718px) {
        .login-container {
            position: static;
            transform: none;
        }

        .login-center {
            min-width: 0;
        }
    }

    .login-form-container {
        display: flex;
        flex-direction: column;
        width: 323px;
        min-width: 323px;
        background: white;
    }

    .login-form-container h1 {
        text-align: start;
        padding: 30px 0;
        font-size: 25px;
        line-height: 10px;
        font-weight: 700;
        font-family: 'Nunito', sans-serif;
    }

    .input-container {
        position: relative;
        margin: 20px 0;
    }

    .input-container input {
        width: 100%;
        padding: 12px;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        border: 1px solid #858C94;
        border-radius: 8px;
        font-family: 'Nunito', sans-serif;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
        margin: 30px 0;
    }

    .login-btn {
        width: 100%;
        height: 40px;
        background: #003E60;
        border: 1px solid #858C94;
        border-radius: 5px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: #e9f4fb;
        cursor: pointer;
        outline: none;
        font-family: 'Nunito', sans-serif;
    }

    @media (hover: hover) {
        .login-btn:hover{
            border-color: #003E60;
            transition: .5s;
        }
    }

    @media (hover: none) {
        .login-btn:active{
            border-color: #003E60;
            transition: .5s;
        }
    }

    .login-logo-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
    }

    .login-logo {
        align-self: start;
        background-size: cover;
        background-repeat: no-repeat;
        width: 145px;
        min-width: 145px;
        height: 21px;
        margin: 30px;
    }
}










