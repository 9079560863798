
.infinite-scroll-loading-spinner-container {
    align-items: center;
    justify-content: center;
    margin: 16px 16px 16px 16px;
}

.infinite-scroll-loading-spinner {
    width: 4.8px;
    height: 4.8px;
    border-radius: 4.8px;
    box-shadow: 12px 0px 0 0 rgba(87,87,87,0.2), 9.7px 7.1px 0 0 rgba(87,87,87,0.4), 3.7199999999999998px 11.4px 0 0 rgba(87,87,87,0.6), -3.7199999999999998px 11.4px 0 0 rgba(87,87,87,0.8), -9.7px 7.1px 0 0 #575757;
    animation: spinner 1s infinite linear;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.back-to-top-button, .infinite-scroll-error {
    align-items: center;
    justify-content: center;
    margin: 10px;
    text-align: center;
    user-select: none;
    font-size: 15px;
    color: #757575;
}

.notifications-window-list .back-to-top-button a {
    font-size: 15px;
    color: #757575;
}
